import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import ProjectsMDX from '../../pages/ProjectsMDX.mdx'
import ProjectsHeader from '../../StaticQueries/ProjectsHeader'
import ProjectsGraphic from '../../StaticQueries/ProjectsGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  // searchInput: {
  //   fontSize: theme.typography.fontSize,
  // },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  container: {
    padding: '48px 36px 0',
  }
})

function ProjectsContent({ classes }) {
  return (
    <>
      <div className={classes.container} style={{color: 'Blue'}}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= S°E°L°E°C°T°E°D ≡≡≡ P°R°O°J°E°C°T°S =-</h1> */}
            <ProjectsHeader/>
          </AppBar>

          <div className={classes.contentWrapper}>
              <ProjectsGraphic/>
              <br/><br/>
              {/* <Typography color="textPrimary" align="justify"> */}
              <typography color="textPrimary" align="justify">
                <ProjectsMDX/>
              </typography>
  						{/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

ProjectsContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProjectsContent)
